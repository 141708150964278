import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryParams } from 'component/hooks/queryParams';
import { getUserSession } from 'utils/token';

export default function LoginBase({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const params = useQueryParams();

  useEffect(() => {
    if (isWpSiteControl) {
      navigate('/login/wpsitecontrol');
    } else {
      if (!getUserSession()) {
        window.localStorage.setItem('returnUrl', params.get('retUrl') ?? '');
        loginWithRedirect();
      } else {
        navigate('/');
      }
    }
  }, []);

  return null;
}
